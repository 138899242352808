import onmount from 'onmount';
import Rails from '@rails/ujs';
import { MDCDataTable } from '@material/data-table';

onmount('.mdc-data-table', function () {
  const self = this;
  const tableBody = this.querySelector('.mdc-data-table__content');
  const blankslate = this.querySelector('[data-js-blankslate]');
  const retainSelection = this.getAttribute('data-table-retain-selection') !== 'false';
  if (!this.MDCDataTable) {
    this.MDCDataTable = new MDCDataTable(this);
    this.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  function getData(columnId, sortValue) {
    const url = new URL(self.dataset.url || window.location.href);
    url.searchParams.set('sort', columnId);
    url.searchParams.set('direction', sortValue);
    Rails.ajax({
      url: url.toString(),
      type: 'GET',
      dataType: 'script',
      error: () => {
        console.error('Failed to filter'); // eslint-disable-line no-console
      }
    });
  }

  function reload() {
    if (!self.dataset.pageUrl) return;

    Rails.ajax({ url: self.dataset.pageUrl, type: 'GET', dataType: 'script' });
  }

  function getSelectableRowsIds() {
    const rows = self.MDCDataTable.getRows();
    const selectableRowsIds = [];
    rows.forEach((row) => {
      const checkbox = row.querySelector('.mdc-data-table__cell--checkbox input');
      if (checkbox && !checkbox.hasAttribute('disabled')) {
        selectableRowsIds.push(row.dataset.rowId);
      }
    });
    return selectableRowsIds;
  }

  function handleSort(e) {
    const { columnId, sortValue } = e.detail;
    self.MDCDataTable.showProgress();
    getData(columnId, sortValue);
  }

  function handleSelectedAll() {
    const selectableRows = getSelectableRowsIds();
    self.MDCDataTable.setSelectedRowIds(selectableRows);
    self.MDCDataTable.foundation.adapter.setHeaderRowCheckboxChecked(true);
  }

  function removeRows() {
    if (retainSelection) {
      tableBody.querySelectorAll('tr:not([aria-selected="true"])').forEach((row) => row.remove());
    } else {
      tableBody.querySelectorAll('tr').forEach((row) => row.remove());
    }
  }

  function removeDuplicateRows() {
    const rows = {};
    tableBody.querySelectorAll('tr[data-row-id]').forEach((row) => {
      if (rows[row.dataset.rowId]) rows[row.dataset.rowId].remove();

      rows[row.dataset.rowId] = row;
    });
  }

  function checkSelectableRows() {
    const selectableRows = getSelectableRowsIds();
    const headerCheckbox = self.querySelector('.mdc-data-table__header-cell--checkbox input');
    if (!headerCheckbox) return;

    if (selectableRows.length) {
      headerCheckbox.removeAttribute('disabled', '');
    } else {
      headerCheckbox.setAttribute('disabled', '');
    }
  }

  function hideBlankslate() {
    if (blankslate) blankslate.classList.add('d-none');
  }

  function showBlankslate() {
    if (blankslate) blankslate.classList.remove('d-none');
  }

  function hidePagination() {
    const pagination = self.querySelector('[data-js-pagination]');
    if (pagination) pagination.classList.add('d-none');
  }

  function showPagination() {
    const pagination = self.querySelector('[data-js-pagination]');
    if (pagination) pagination.classList.remove('d-none');
  }

  function appendRows(data) {
    tableBody.insertAdjacentHTML('beforeend', data);
  }

  function appendData(e) {
    const { data } = e.detail;

    appendRows(data);

    self.MDCDataTable.layout();
    self.MDCDataTable.hideProgress();
  }

  function showNewData(e) {
    const selectedRowIds = getSelectableRowsIds().length ? self.MDCDataTable.getSelectedRowIds() : [];
    removeRows();

    const { data, totalCount, pageUrl } = e.detail;

    if (pageUrl !== undefined && totalCount !== undefined) {
      tableBody.innerHTML = data;
      self.setAttribute('data-total-count', totalCount);
      self.setAttribute('data-page-url', pageUrl);
      self.MDCDataTable.emit('MDCDataTable:unselectedAll');
    } else {
      appendRows(data);
    }

    removeDuplicateRows();

    checkSelectableRows();

    self.MDCDataTable.layout();
    self.MDCDataTable.hideProgress();
    if (selectedRowIds.length) self.MDCDataTable.setSelectedRowIds(selectedRowIds);

    if (self.MDCDataTable.getRows().length) {
      hideBlankslate();
      showPagination();
    } else {
      showBlankslate();
      hidePagination();
    }
  }

  this.MDCDataTable.listen('MDCDataTable:sorted', handleSort);
  this.MDCDataTable.listen('MDCDataTable:selectedAll', handleSelectedAll);
  this.addEventListener('MDCDataTable:loadData', showNewData);
  this.addEventListener('MDCDataTable:appendData', appendData);
  this.addEventListener('MDCDataTable:reload', reload);
  checkSelectableRows();
}, function () {
  this.MDCDataTable.destroy();
});
